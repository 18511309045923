import { fadeIn } from 'src/layout/keyframes';
import styled from 'styled-components';
import { size } from '../../layout/helpers';

export const WidgetWrapper = styled.div<{ $align?: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.$align || 'center'};
  justify-content: center;
  gap: ${size(3)};
  text-align: center;
  height: 100%;

  * {
    margin: 0 0;
  }
`;
export const Content = styled.div<{ $align?: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.$align || 'center'};
  justify-content: center;
  gap: ${size(2)};
  text-align: ${(props) =>
    ({
      start: 'left',
      center: 'center',
      end: 'right',
    }[props.$align || 'center'])};
  height: 100%;
`;

export const WidgetFadeIn = styled.div`
  animation: ${fadeIn} 0.3s ease-in-out;
  width: 100%;
`;
