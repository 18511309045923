import 'core-js';
import 'regenerator-runtime/runtime';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import * as Sentry from '@sentry/react';
import sv from 'date-fns/locale/sv';
import React from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import App from './App';
import Providers from './Providers';
import './i18n';
import GlobalStyle from './layout/global-style';
import theme from './layout/theme';
import { sentryConfig } from './sentry';

registerLocale('sv', sv);
setDefaultLocale('sv');

Sentry.init(sentryConfig);
ReactDOM.render(
  <Providers>
    <Helmet>
      <script type="text/javascript">
        {/* eslint-disable react/no-unescaped-entities */}
        const _sid = "2753";
      </script>

      <script
        type="text/javascript"
        src={`${
          'https:' == document.location.protocol ? 'https://' : 'http://'
        }files.imbox.io/app/dist/initWidget.js`}
        async
      />
    </Helmet>
    <ThemeProvider theme={theme}>
      <GlobalStyle theme={theme} />
      <App />
    </ThemeProvider>
  </Providers>,
  document.querySelector('#root')
);
