import { lazy } from 'react';
import { RouteItem } from '../@types/routes';
import i18n from '../i18n';

const Estates = lazy(() => import('./Reports/Estates'));
const Home = lazy(() => import('./Home'));
const NotFound = lazy(() => import('./NotFound'));
const Services = lazy(() => import('./Services'));
const Scheduled = lazy(() => import('./Services/Scheduled'));
const Statistics = lazy(() => import('./Reports/Statistics'));
const HazardousWaste = lazy(() => import('./Reports/HazardousWaste'));
const Trends = lazy(() => import('./Trends/MaterialsByMonth'));
const Users = lazy(() => import('./Users'));
const WasteHierarchy = lazy(() => import('./Trends/WasteHierarchy'));
const WasteHierarchyEstate = lazy(() => import('./Reports/WasteHierarchyEstate'));
const WorkplaceAnalysis = lazy(() => import('./Trends/WorkplaceAnalysis'));
const Invoices = lazy(() => import('./Administration/Invoices'));
const MyPages = lazy(() => import('./Administration/MyPages'));
const ApiClients = lazy(() => import('./Administration/ApiClients/ApiClients'));
const Workplaces = lazy(() => import('./Workplaces'));
const SustainabilityReport = lazy(() => import('./Reports/SustainabilityReport'));
const UserAdmins = lazy(() => import('./UserAdmins'));
const Deviations = lazy(() => import('./Deviations'));
const Scope3 = lazy(() => import('./Trends/Scope3'));
const EstateScope3 = lazy(() => import('./Reports/Estates/Scope3'));

export const privateRoutes: RouteItem[] = [
  {
    title: i18n.t('home_title'),
    path: '/',
    component: Home,
    exact: true,
  },
  {
    title: i18n.t('waste_hierarchy_title'),
    path: '/waste-hierarchy',
    component: WasteHierarchy,
    exact: true,
  },
  {
    title: i18n.t('workplace_analysis_title'),
    path: '/workplace-analysis',
    component: WorkplaceAnalysis,
  },
  {
    title: i18n.t('services_title'),
    path: '/services',
    component: Services,
  },
  {
    title: i18n.t('scheduled_services_title'),
    path: '/scheduled',
    component: Scheduled,
  },
  {
    title: i18n.t('reports_title'),
    path: '/reports/statistics',
    component: Statistics,
  },
  {
    title: i18n.t('hazardous_waste_title'),
    path: '/reports/waste',
    component: HazardousWaste,
  },
  {
    title: i18n.t('estates_title'),
    path: '/estates',
    component: Estates,
    exact: true,
  },
  {
    title: i18n.t('estates_waste_hierarchy_h1'),
    path: '/estates/waste-hierarchy',
    component: WasteHierarchyEstate,
  },
  {
    title: i18n.t('estates_scope3_title'),
    path: '/estates/scope3',
    component: EstateScope3,
    exact: true,
  },
  {
    title: i18n.t('users_title'),
    path: '/users',
    component: Users,
  },
  {
    title: i18n.t('trends_title'),
    path: '/trends',
    component: Trends,
  },
  {
    title: i18n.t('scope3_title'),
    path: '/scope3',
    component: Scope3,
    exact: true,
  },
  {
    title: i18n.t('sustainability_report'),
    path: '/sustainability-report',
    component: SustainabilityReport,
    exact: true,
  },
  {
    title: i18n.t('my_pages'),
    path: '/admin/my-pages',
    component: MyPages,
    exact: true,
  },
  {
    title: i18n.t('invoices_title'),
    path: '/invoices',
    component: Invoices,
  },
  {
    title: 'API Nycklar',
    path: '/admin/api-clients',
    component: ApiClients,
    exact: true,
  },
  {
    title: i18n.t('workplaces_title'),
    path: '/workplaces',
    component: Workplaces,
  },
  {
    title: 'Remondis admins',
    path: '/remondis-admins',
    component: UserAdmins,
  },
  {
    title: i18n.t('deviations_title'),
    path: '/deviations',
    component: Deviations,
  },
  {
    title: i18n.t('not_found_title'),
    component: NotFound,
  },
];
