import { Container } from '@components/Container';
import { H4 } from '@components/Heading';
import { Text } from '@components/Text';
import React from 'react';
import styled from 'styled-components';

type WrapperProps = {
  $warning?: boolean;
};

const Wrapper = styled.section<WrapperProps>`
  color: white;
  background: ${(props) => (props.$warning ? '#b40b1a' : props.theme.color.bg.medium)};
  color: ${(props) => (props.$warning ? 'white' : props.theme.color.text.main)};
  display: flex;
  padding: 32px 0;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextWrapper = styled.div`
  max-width: 900px;
  a {
    color: inherit;
  }
`;

const MaintenanceBanner = () => {
  return (
    <Wrapper>
      <Container>
        <Inner>
          <TextWrapper>
            <H4>De tekniska störningarna är åtgärdade.</H4>
            <Text>
              Tack för ditt tålamod! För eventuella frågor, ring vår kundtjänst på{' '}
              <a href="tel:0771545000" title="Ring kundtjänst">
                0771 – 545 000
              </a>
              .
            </Text>
          </TextWrapper>
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default MaintenanceBanner;
