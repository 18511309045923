import useAuth from '@State/Auth/useAuth';
import useImpersonate from '@State/Impersonate/useImpersonate';
import { Base, BaseFooter, BaseHeader, BaseLoader } from '@components/Base/index';
import Footer from '@components/Footer/index';
import HeaderContext from '@components/Header/HeaderContext';
import Header from '@components/Header/index';
import Loader from '@components/Loader/index';
import MaintenanceBanner from '@components/MaintenanceBanner';
import { PortalElement, PortalNamespace } from '@components/Portal';
import Snackbar from '@components/Snackbar';
import TermsAgreementModal from '@components/TermsAgreementModal';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import ImpersonateBar from './ImpersonateBar';
import Routes from './routes/index';
import { useRoutes } from './routes/useRoutes';

declare global {
  interface Window {
    _mtm: any;
  }
}

const RemoveTrailingSlash = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname.endsWith('/') && location.pathname !== '/') {
      const newPathname = location.pathname.slice(0, -1);
      history.replace(newPathname);
    }
  }, [location, history]);

  return null;
};

const App = () => {
  const { t } = useTranslation();
  const { token, parsedToken } = useAuth();
  const { hasFetched, getImpersonate } = useImpersonate();
  const { isPrivateRoute } = useRoutes();

  const shouldShowUserAgreement = isPrivateRoute && parsedToken && parsedToken?.terms?.v !== '1.0';

  // This enables the maintenance banner and should be set somewhere else
  const maintenanceMode = false;

  useEffect(() => {
    if (parsedToken?.useradmin && token) getImpersonate();
  }, [!!token]);

  if (parsedToken?.useradmin && !hasFetched) {
    return (
      <Base>
        <BaseLoader>
          <Loader />
        </BaseLoader>
      </Base>
    );
  }

  return (
    <>
      <RemoveTrailingSlash />

      {shouldShowUserAgreement && <TermsAgreementModal />}
      <Snackbar />
      <Base>
        {maintenanceMode && <MaintenanceBanner />}
        <ImpersonateBar />

        <BaseHeader>
          <HeaderContext>
            <Header />
          </HeaderContext>
        </BaseHeader>

        <Routes />

        <BaseFooter>
          <Footer
            copyright={t('footer_copyright')}
            links={[
              {
                text: t('footer_link_privacy_policy_text'),
                title: t('footer_link_privacy_policy_title'),
                path: '/privacy-policy',
                routerLink: true,
              },
              {
                text: t('termsofuse_title'),
                title: t('termsofuse_title'),
                path: '/terms-of-use',
                routerLink: true,
              },
            ]}
          />
        </BaseFooter>
      </Base>
      <PortalElement id={PortalNamespace} />

      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default App;
